.picker-container{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 550px;
}
.picker-title{
    text-align: center;
}

.picker-content{
    box-shadow:0px 0px 15px #888888;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    height:inherit;
}

.picker-announce{
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background-image: linear-gradient(to right top, #051937, #004d7a, #008793, #00bf72, #a8eb12);
    color: white;
    background-blend-mode: multiply;
    margin-left: 16px;

}

.picker-participants-panel{
    background-color: white;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    align-items: center;
}

.participants-panel-input{
    height: 100%;
    width: 100%;
}
